// Ce qui marche à 15h24 le 29/08/2024

import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Box, Divider, useMediaQuery, IconButton, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { removeBlog } from '../redux/blogSlice';
import afluensBack from '../assets/animAfluens.webp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { fetchOgImage } from '../services/api'; 

const BlogCard = ({ blog }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [ogImage, setOgImage] = useState(null); 
  const [loading, setLoading] = useState(true); 

  const { id, attributes } = blog || {};

  useEffect(() => {
    const loadOgImage = async () => {
      const url = attributes?.pdfUrl || attributes?.googleLink;
      if (url) {
        try {
          const image = await fetchOgImage(url);
          console.log("URL analysée:", url);
          console.log("Image Open Graph récupérée:", image);
          setOgImage(image);
        } catch (error) {
          console.error("Erreur lors de la récupération de l'image Open Graph:", error);
        } finally {
          setLoading(false); // Fin du chargement, qu'une image soit trouvée ou non
        }
      } else {
        setLoading(false); // Pas d'URL pour récupérer l'image, donc fin du chargement
      }
    };

    loadOgImage();
  }, [attributes?.pdfUrl, attributes?.googleLink]);

  if (!attributes || loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "500px" }}>
        <CircularProgress />
      </Box>
    );
  }

  const { blogTitle, blogDesc, youtubeUrl, pdfUrl, googleLink, createdAt, bloc } = attributes;

  const handleDelete = () => {
    if (window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
      dispatch(removeBlog(id));
      navigate(`/blogs/${bloc}`);
    }
  };

  const handleEdit = () => {
    navigate(`/edit/${id}`);
  };

  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };


  const mediaSrc = youtubeUrl 
    ? youtubeUrl 
    : (ogImage || afluensBack); 

  const mediaTitle = youtubeUrl ? blogTitle : "";

  const hasLink = youtubeUrl || pdfUrl || googleLink;

  return (
    <Card sx={{ display: 'flex', width: 'auto', margin: 2, height: "500px", boxShadow: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <CardMedia
              component={youtubeUrl ? "iframe" : "img"}
              height={isMobile ? "250px" : "100%"}
              src={mediaSrc}
              title={mediaTitle}
              sx={{ width: '100%' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 1 }}>
              {user && user.admin && (
                <>
                  <IconButton onClick={handleEdit} sx={{ color: 'black', fontSize: '24px' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={handleDelete} sx={{ color: 'black', fontSize: '24px' }}>
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
              {hasLink && (
                <IconButton onClick={() => handleButtonClick(youtubeUrl || pdfUrl || googleLink)} sx={{ color: 'black', fontSize: '24px' }}>
                  <OpenInNewIcon />
                </IconButton>
              )}
            </Box>
            <Box sx={{ textAlign: 'center', mb: 1 }}>
              <Typography
                variant={isMobile ? "h8" : "h6"}
                component="div"
                sx={{ fontWeight: 'bold', color: 'primary.main', fontSize: isMobile ? '0.60rem':'1rem' }}
              >
                {blogTitle}
              </Typography>
            </Box>
            <Divider sx={{ mb: isMobile ? 1 : 2 }} />
            <Box sx={{ mt: 0.2, flexGrow: 1 }}>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  fontSize: isMobile ? '0.57rem' : '0.90rem',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: 7,
                  textOverflow: 'ellipsis',
                }}
              >
                {blogDesc}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontStyle: 'italic', fontSize: isMobile ? '0.67rem' : '0.875rem', pt: isMobile ? 0 : 2 }}
              >
                Publié le : {new Date(createdAt).toLocaleDateString()}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Typography
                variant="body2"
                sx={{
                  display: 'inline-block',
                  backgroundColor: 'white',
                  color: 'black',
                  textAlign: 'center',
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                  mt: isMobile ? 0 : 1,
                  px: 2,
                  py: 0.5,
                  borderRadius: '50px',
                  border: '1px solid black',
                }}
              >
                {bloc.toUpperCase()}
              </Typography>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BlogCard;

